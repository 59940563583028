import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

//import 'tablesaw/dist/tablesaw.jquery';
import libs from './lib/dependencies';
window.libs = libs;

$(document).foundation();


//Text slider simple animation
$(".textslider > div:gt(0)").hide();
setInterval(function () {
    $('.textslider > div:first').fadeOut(700).next().fadeIn(700).end().appendTo('.textslider');
}, 5000);



//smooth scrolling for navigation buttons
$('.circlular_buttons a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    var target = this.hash;
    var $target = $(target);
    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 1000, 'swing', function () {
        window.location.hash = target;
    });
});


//Animated Top bar
$('.title-bar').on('sticky.zf.stuckto:top', function () {
    $(this).addClass('shrink');
}).on('sticky.zf.unstuckfrom:top', function () {
    $(this).removeClass('shrink');
});


//blur background image on circle hover
$( ".accordion-item .circle:not('.profil_circle')" ).hover(
  function() {
   $( this ).parent().parent().parent().parent().find(".parallax-background").toggleClass( "blured" );
  }
);

//Animate on Scroll init
var AOS = require('aos');
AOS.init({
    //once: true
    disable: 'mobile'
});





//Parallax effekt

$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  $(".parallax-background").css("transform","translateY(" +  (scroll/3)  + "px)");    
});